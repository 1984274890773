import tinycolor2 from 'tinycolor2';

export function getImage(url: String){
    return new Promise<HTMLImageElement>((resolve, reject) => {
        let img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = url.toString();
    });
}

export function canvas2blob(canvas: HTMLCanvasElement){
    return new Promise<Blob>((resolve, reject) => {
        canvas.toBlob(blob => {
            if(blob){
                resolve(blob);
            }
            else{
                reject('canvas.toBlob failed');
            }
        });
    });
}

export type PhotoshopColor = {
    a: number,
    hex: string,
    hex8: string,
    hsl: {
        a: number,
        h: number,
        l: number,
        s: number,
    },
    hsv: {
        a: number,
        h: number,
        s: number,
        v: number,
    },
    rgba: {
        a: number,
        r: number, 
        g: number, 
        b: number,
    },
    source: string,
};

export function fromRGBA(r: number, g: number, b: number, a: number): PhotoshopColor{
	let tc = new tinycolor2({r, g, b, a,});
	return {
		a,
		hex: `#${tc.toHex()}`,
		hex8: `#${tc.toHex8()}`,
		hsl: tc.toHsl(),
		hsv: tc.toHsv(),
		rgba: tc.toRgb(),
		source: 'rgba',
	};
}

export function white(a: number = 1): PhotoshopColor{
	return fromRGBA(255, 255, 255, a);
}

export function black(a: number = 1): PhotoshopColor{
    return fromRGBA(0, 0, 0, a);
}