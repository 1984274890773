
















































import { black, white } from '@/utils';
import Vue from 'vue';
import {Component, Watch} from 'vue-property-decorator';
import FontGenerator from './FontGenerator.vue';
import ImageLetter from "./ImageLetter.vue";
import NamedProperty from './NamedProperty.vue';
import PxSwatch from './PxSwatch.vue';
import ColorSwatch from './ColorSwatch.vue';
import InfoLabel from './InfoLabel.vue';

function getTTFFamilyname(file: File){
	return file.name.slice(0, file.name.indexOf('.ttf'));
}

type letter = {
	char: string,
	image: HTMLImageElement,
}

@Component({
	components: {
        FontGenerator,
        ImageLetter,
        NamedProperty,
        PxSwatch,
        ColorSwatch,
        InfoLabel,
	}
})
export default class FromTTF extends Vue{
    name = 'FromTTF';
    ttf: File = null as any as File;
    chars = '1234567890';
    letters: letter[] = [];
    fillStyle = {
        color: black(),
        px: 128,
    };

    stroke = {
        use: true,
        color: white(),
        px: 2,
    };

    shadow = {
        use: true,
        color: black(0.25),
        x: 4,
        y: 4,
        blur: 1,
    }

    get font(){
        return {
            size: this.fillStyle.px,
            face: this.ttf ? getTTFFamilyname(this.ttf) : 'unknown',
            strokeWidth: this.stroke.px,
        };
    }

    get fontName(){
        return this.ttf ? getTTFFamilyname(this.ttf) : undefined;
    }

    mounted(){
        this.drawImages();
    }

    onSelectFiles(event: any){
		var files = event.target.files || event.dataTransfer.files;
        if(files.length){
            this.ttf = files[0];
        }
	}

    @Watch('chars')
    @Watch('ttf')
    @Watch('fillStyle', {deep: true})
    @Watch('stroke', {deep: true})
    @Watch('shadow', {deep: true})
	async drawImages(){
        if(this.ttf){
            let familyname = getTTFFamilyname(this.ttf);
            if(![...document.fonts.values()].some(ff => ff.family == familyname)){
                let fr = new FileReader();
			    await new Promise(resolve => {
				    fr.addEventListener('loadend', resolve);
				    fr.readAsArrayBuffer(this.ttf as Blob);	// typing miss. readAsArrayBuffer accepts File.
			    });
			
			    if(fr.result){
				    let ff = new FontFace(familyname, fr.result);	// error but works
				    await ff.load();
                    document.fonts.add(ff);
                }
            }
        }
        
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d') as CanvasRenderingContext2D;  // assertion
        let familyname = this.ttf ? `'${getTTFFamilyname(this.ttf)}'` : 'serif';

        let chars = [...this.chars].filter((v, i, self) => self.indexOf(v) == i);
        let font = `${this.fillStyle.px}px ${familyname}`;
        
        ctx.font = font;
        let wholeTM = ctx.measureText(chars.join(''));
        let outsets = {
            l: 0,
            t: 0,
            r: 0,
            b: 0,
        };
        if(this.stroke.use){
            let halfStrokeWidth = this.stroke.px / 2;
            outsets.l += halfStrokeWidth;
            outsets.t += halfStrokeWidth;
            outsets.r += halfStrokeWidth;
            outsets.b += halfStrokeWidth;
        }

        // TODO: regards shadow.blur?
        if(this.shadow.use){
            if(this.shadow.x > 0){
                outsets.r = Math.max(this.shadow.x, outsets.r);
            }
            else{
                outsets.l = Math.max(-this.shadow.x, outsets.l);
            }

            if(this.shadow.y > 0){
                outsets.b = Math.max(this.shadow.y, outsets.b);
            }
            else{
                outsets.t = Math.max(-this.shadow.y, outsets.t);
            }
        }

        this.letters = await Promise.all(chars.map(char => {
            ctx.font = font;
            let charTM = ctx.measureText(char);
            
            canvas.width = charTM.actualBoundingBoxRight - charTM.actualBoundingBoxLeft + outsets.l + outsets.r;

            // spacing char. TODO: tab, newline...
            if(char == ' '){
                canvas.width = charTM.width;
            }

            canvas.height = Math.ceil(wholeTM.actualBoundingBoxAscent) + Math.ceil(wholeTM.actualBoundingBoxDescent) + outsets.t + outsets.b;
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            let x = outsets.l;
            let y = canvas.height - Math.ceil(wholeTM.actualBoundingBoxDescent) - outsets.b;

            // fill with shadow
            if(this.shadow.use){
                ctx.shadowOffsetX = this.shadow.x;
                ctx.shadowOffsetY = this.shadow.y;
                ctx.shadowColor = this.shadow.color.hex8;
                ctx.shadowBlur = this.shadow.blur;
                ctx.fillStyle = this.fillStyle.color.hex8;
                ctx.font = font;
                ctx.fillText(char, x, y);

                // clear shadow
                ctx.shadowOffsetX = 0;
                ctx.shadowOffsetY = 0;
                ctx.shadowColor = "rgba(0,0,0,0)";
            }

            // stroke
            if(this.stroke.use){
                ctx.font = font;
                ctx.strokeStyle = this.stroke.color.hex8;
                ctx.lineWidth = this.stroke.px;
                ctx.strokeText(char, x, y);
            }

            // fill
            ctx.fillStyle = this.fillStyle.color.hex8;
            ctx.font = font;
            ctx.fillText(char, x, y);

            let image = new Image(canvas.width, canvas.height);
            let waiting = new Promise<letter>(resolve => {
                image.onload = () => resolve({
                    char,
                    image,
                });
            });
            image.src = canvas.toDataURL();
            return waiting;
        }));
	}
}

