
























































import ImageLetter from "./ImageLetter.vue";
import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import {getImage} from '../utils';
import FontGenerator from './FontGenerator.vue';

type letter = {
	char: string,
	file: File,
	image: HTMLImageElement,
}

@Component({
	components: {
		ImageLetter,
		FontGenerator,
	}
})
export default class FromImages extends Vue{
    name = 'FromImages';
	letters: letter[] = [];
	dragging = false;
	placeholderLetters: letter[] = [];
	
	async addFiles(files: File[]){
		// TODO: compare file path
		files = files.filter(file => !this.letters.some(letter => letter.file == file));

		if(files.length){
			this.letters = this.letters.filter(letter => !this.placeholderLetters.some(l => l == letter));

			for(let file of files){
				let image = await getImage(URL.createObjectURL(file));
				this.letters.push({
					file,
					image,
					char: 'A',
				});
			}
		}
	}
	
	mounted() {
		let dummyFile = new File([], '');
		for(let i = 0; i < 10; ++i){
			let image = this.$refs[`placeholder_${i}`] as HTMLImageElement; 
			image.onload = () => {
				let letter = {
					char: `${i}`,
					file: dummyFile,
					image,
				};
				this.placeholderLetters.push(letter);
				this.letters.push(letter);
			}
		}
	}

	dragenter(event: any){
		event.preventDefault();
		this.dragging = true;
	}

	dragover(event: any){
		event.preventDefault();
		this.dragging = true;
	}

	dragleave(event: any){
		event.preventDefault();
		this.dragging = false;
	}
	
    drop(event: any) {
		event.preventDefault();
		this.dragging = false;
		this.addFiles([...event.dataTransfer.files]);	// arraylike to array
	}
	
	onSelectFiles(event: any){
		var files = event.target.files || event.dataTransfer.files;
		this.addFiles([...files]);	// arraylike to array
	}

	onChangeChar(index: number, char: string) {
		this.letters[index].char = char;
		// this.updateFnt();
	}

	onDeleteChar(index: number){
		this.letters.splice(index, 1);
		if(!this.letters.length){
			this.letters.push(...this.placeholderLetters);
		}
	}
}
