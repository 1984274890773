






















import { Photoshop, Sketch } from 'vue-color'
import Vue from 'vue';
import {Component, Model} from 'vue-property-decorator';
import { PhotoshopColor } from '@/utils';

@Component({
	components: {
        Photoshop,
        Sketch,
	}
})
export default class ColorSwatch extends Vue{
    private static componentId = 0;

    @Model('input')
    colors!: PhotoshopColor;

    hovering = false;
    uniqueID!: string;

    created(){
        this.uniqueID = `ColorSwatch-${++ColorSwatch.componentId}`;
    }

    get mycolor(){
        return this.colors;
    }

    set mycolor(val: PhotoshopColor){
        this.$emit('input', val)
    }

    get defaultStyle(){
        let blight = this.colors.hsl.l > 0.5;
        let counterGrayscale = blight ? 'black' : 'white';
        return this.hovering ? {
            color: this.colors.hex8,
            'background-color': counterGrayscale,
            'border-color': counterGrayscale,
            'transition-duration': '0.4s',
        }: {
            color: counterGrayscale,
            'background-color': this.colors.hex8,
            'border-color': this.colors.hex8,
            'transition-duration': '0.4s',
        };
    }
}
