






























import Vue from 'vue';
import {Component, Prop, Model} from 'vue-property-decorator';
import InfoLabel from './InfoLabel.vue';

@Component({
	components: {
        InfoLabel,
	}
})
export default class NamedProperty extends Vue{
    @Prop({required: true})
    title!: string;

    @Prop({required: false})
    readonly footnoteID!: string;

    @Model('input')
    enabled!: boolean;

    get useenabled(){
        return this.enabled == true || this.enabled == false;
    }

    get myenabled(){
        return !this.useenabled || this.enabled;
    }

    set myenabled(val: boolean){
        this.$emit('input', val)
    }
}

