









































import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
@Component({
	components: {
	}
})
export default class FootNote extends Vue{
    static footNotes: Record<string, HTMLElement> = {};

    mounted(){
        this.slotNames.reduce((acc, name: string) => {
            acc[name] = (this.$refs[`footnote_${name}`] as HTMLElement[])[0] as HTMLElement;
            return acc;
        }, FootNote.footNotes);
    }

    get slotNames(){
        return Object.keys(this.$slots);
    }
};
