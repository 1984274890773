














































































































































import FromImages from "./components/FromImages.vue";
import FromTTF from "./components/FromTTF.vue";
import Vue from 'vue';
import InfoLabel from './components/InfoLabel.vue';
import {Component} from 'vue-property-decorator';
import FootNote from './components/FootNote.vue';

@Component({
	components: {
		FromImages,
		FromTTF,
		InfoLabel,
		FootNote,
	}
})
export default class app extends Vue{
    dname='dname';
}
