



















































import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import {black, canvas2blob, getImage, white} from '../utils';
import JSZip from 'jszip';
import NamedProperty from './NamedProperty.vue';
import ColorSwatch from './ColorSwatch.vue';
import InfoLabel from './InfoLabel.vue';
import xmlbuilder from 'xmlbuilder';

export type font = {
	size?: number,
	face?: string,
	strokeWidth?: number,
	baseline?: number,
};

export type letter = {
	char: string,
	image: HTMLImageElement,
};

@Component({
	components: {
		NamedProperty,
		ColorSwatch,
		InfoLabel,
	}
})
export default class FontGenerator extends Vue{
	name = 'FontGenerator';
    @Prop({required: true})
	letters!: letter[];
	
	@Prop({required: false})
	font!: font;

    canvas: HTMLCanvasElement = null as any as HTMLCanvasElement;
	fnt_xml: string = '';
	fnt_txt: string = '';
	fontName: string = "image_font";
	
    multiply = {
		use: false,
		color: white(),
	};

	add = {
		use: false,
		color: black(),
	};

	get fontEnsured(): Required<font>{
		return Object.assign({
		size: 32,
		face: "Arial",
		strokeWidth: 0,
		baseline: 26,
		}, this.font);
	}
	
	mounted() {
		this.canvas = this.$refs["canvas"] as HTMLCanvasElement;
	}

	@Watch('letters')
	async onLetters(){
		await this.updateCanvas();
		this.updateFnt();
	}

	@Watch('fontName')
	@Watch('fontEnsured')
	async onFontName(){
		this.updateFnt();
	}

	@Watch('multiply', {deep: true})
	@Watch('add', {deep: true})
	onColor(){
		this.updateCanvas();
	}
    
    updateFnt() {
		let font = xmlbuilder.create("font");
		let txt_lines: string[] = [];
		font.ele("info", {
			face: this.fontEnsured.face,
			size: this.fontEnsured.size,
			bold: 0,
			italic: 0,
			charset: "",
			unicode: 1,
			stretchH: 100,
			smooth: 1,
			aa: 1,
			padding: "0,0,0,0",
			spacing: "1,1",
			outline: 0
		});

		txt_lines.push(`info aa=1 size=${this.fontEnsured.size} smooth=1 stretchH=100 bold=0 padding=0,0,0,0 spacing=1,1 charset="" italic=0 unicode=1 face="${this.fontEnsured.face}" outline=0`);

		font.ele("common", {
			lineHeight: this.fontEnsured.size,
			base: 26,
			scaleW: this.canvas.width,
			scaleH: this.canvas.height,
			pages: 1,
			packed: 0,
			alphaChnl: 1,
			redChnl: 0,
			greenChnl: 0,
			blueChnl: 0
		});

		txt_lines.push(`common lineHeight=${this.fontEnsured.size} base=26 scaleW=${this.canvas.width} scaleH=${this.canvas.height} pages=1 packed=0 alphaChnl=1 redChnl=0 greenChnl=0 blueChnl=0`);

		font.ele("pages").ele("page", {
			id: 0,
			file: `${this.fontName}.png`
		});

		txt_lines.push(`page id=0 file="${this.fontName}.png"`);

		let chars = font.ele("chars", {
			count: this.letters.length
		});

		txt_lines.push(`chars count=${this.letters.length}`);

		let padding = 5;
		let x = padding;
		
		this.letters.forEach(letter => {
			let options = {
				id: letter.char.charCodeAt(0),
				x: x,
				y: 0,
				width: letter.image.naturalWidth,
				height: letter.image.naturalHeight,
				xoffset: -this.fontEnsured.strokeWidth / 2,
				yoffset: (this.fontEnsured.size - letter.image.naturalHeight) / 2,	// cocos creator specific
				xadvance: letter.image.naturalWidth - this.fontEnsured.strokeWidth,
				page: 0,
				chnl: 15
			};
			
			chars.ele("char", options);

			let fields = [
				`char`,
				`id=${options.id.toString().padEnd(5)}`,
				`x=${options.x.toString().padEnd(5)}`,
				`y=${options.y.toString().padEnd(5)}`,
				`width=${options.width.toString().padEnd(5)}`,
				`height=${options.height.toString().padEnd(5)}`,
				`xoffset=${options.xoffset.toString().padEnd(5)}`,
				`yoffset=${options.yoffset.toString().padEnd(5)}`,
				`xadvance=${options.xadvance.toString().padEnd(5)}`,
				`page=${options.page.toString().padEnd(5)}`,
				`chnl=${options.chnl.toString().padEnd(5)}`,
			];

			txt_lines.push(fields.join(' '));

			x += letter.image.naturalWidth + padding;
		});

		this.fnt_xml = font.end({ pretty: true });
		this.fnt_txt = txt_lines.join('\n');
	}

	async updateCanvas() {
		// TODO: clamped padding
		let padding = 5;
		
		let canvasSize = this.letters.reduce(
			(accum, letter) => {
				return {
					width: accum.width + letter.image.naturalWidth + padding,
					height: Math.max(accum.height, letter.image.naturalHeight)
				};
			},
			{ width: padding, height: 1 }
		);
		this.canvas.width = canvasSize.width;
		this.canvas.height = canvasSize.height;

		let ctx = this.canvas.getContext("2d");

		if(ctx){
			// TODO : clear?
			let x = padding;
			for (let i = 0; i < this.letters.length; ++i) {
				let letter = this.letters[i];
				ctx.drawImage(letter.image, x, 0);
				x += letter.image.naturalWidth + padding;
			}

			// multiply
			{
				let imageData = ctx.getImageData(0, 0, this.canvas.width, this.canvas.height);
				let data = imageData.data;
				let mColor = this.multiply.use ? this.multiply.color.rgba : {r: 255, g: 255, b: 255};
				let aColor = this.add.use ? this.add.color.rgba : {r: 0, g: 0, b: 0};
				for(let x = 0; x < imageData.width; ++x){
					for(let y = 0; y < imageData.height; ++y){
						let offset = (y * imageData.width + x) * 4;
						data[offset + 0] = data[offset + 0] * mColor.r / 255 + aColor.r;
						data[offset + 1] = data[offset + 1] * mColor.g / 255 + aColor.g;
						data[offset + 2] = data[offset + 2] * mColor.b / 255 + aColor.b;
					}
				}
				ctx.putImageData(imageData, 0, 0);
			}
			//
		}
	}

	async download() {
		let zip = new JSZip();
		zip.file(`${this.fontName}.xml.fnt`, this.fnt_xml);
		zip.file(`${this.fontName}.txt.fnt`, this.fnt_txt);

		let blob = await canvas2blob(this.canvas);
		zip.file(`${this.fontName}.png`, blob);

		let canvas = document.createElement('canvas');
		let ctx = canvas.getContext("2d");
		let lFolder = zip.folder('letters');
		if(ctx && lFolder){
			for(let l of this.letters){
				canvas.width = l.image.naturalWidth;
				canvas.height = l.image.naturalHeight;
				ctx.clearRect(0, 0, canvas.width, canvas.height);
				ctx.drawImage(l.image, 0, 0);
				let lBlob = await canvas2blob(canvas);
				lFolder.file(`${l.char.charCodeAt(0)}.png`, lBlob);
			}
		}

		let base64 = await zip.generateAsync({ type: "base64" });
		
		let tempAnchor = document.createElement("a");
		tempAnchor.href = `data:application/zip;base64,${base64}`;
		tempAnchor.download = `${this.fontName}.zip`;
		tempAnchor.click();
	}
}
