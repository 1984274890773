















































































import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

import { createPopper } from '@popperjs/core';
import type { StrictModifiers } from '@popperjs/core';
import FootNote from './FootNote.vue';

@Component({
	components: {
	}
})
export default class InfoLabel extends Vue{
    private static componentId = 0;

	@Prop({required: true})
    readonly text!: string;

    @Prop({required: false})
    readonly footnoteID!: string;
    
    uniqueID!: string;

    created(){
        this.uniqueID = `InfoLabel-${++InfoLabel.componentId}`;
    }
    
    mounted(){
        if(this.footnoteID){
            const reference = this.$refs['reference'] as Element;
            const showEvents = ['mouseenter', 'focus'];
            const hideEvents = ['mouseleave', 'blur'];
            const popper = this.$refs['popper'] as HTMLElement;
            document.body.appendChild(popper);  // prevent size blinking
            let popperContent! : HTMLElement;
            showEvents.forEach(event => {
                reference.addEventListener(event, () => {
                    if(!popperContent){
                        popperContent = FootNote.footNotes[this.footnoteID].cloneNode(true) as HTMLElement;
                        popper.appendChild(popperContent);
                    }
                    popper.setAttribute('data-show', '');
                    createPopper<StrictModifiers>(reference, popper, {
                        placement: 'top',
                        modifiers: [{
                            name: 'offset',
                            options: {
                                offset: [0, 8],
                            },
                        },],
                    });
                });
            });

            hideEvents.forEach(event => {
                reference.addEventListener(event, () => {
                    popper.removeAttribute('data-show');
                });
            });
        }
    }
};
