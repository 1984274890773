





















import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import {getImage} from '../utils';

@Component
export default class ImageLetter extends Vue{
	name =  "ImageLetter";
	
	@Prop({required: true})
	readonly image!: HTMLImageElement;
	
	get style(){
		let naturalAspect = this.image.naturalWidth / this.image.naturalHeight;
		return {
			width: `${200 * naturalAspect}px`, 
			height: `${200}px` 
		};
	}
};
