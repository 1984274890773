





















var NumberInputSpinner = require('vue-number-input-spinner');
import Vue from 'vue';
import {Component, Model, Prop} from 'vue-property-decorator';

@Component({
	components: {
        NumberInputSpinner,
	}
})
export default class PxSwatch extends Vue{
    @Model('input')
    value!: number;

    @Prop()
    title!: string;

    get mytitle(){
        return this.title ? this.title : '';
    }

    get myvalue(){
        return this.value;
    }

    set myvalue(val: number){
        this.$emit('input', val)
    }
}

