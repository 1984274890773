import Vue from 'vue'
import App from './App.vue'
import {BootstrapVue, BIcon, BIconQuestionCircle} from 'bootstrap-vue'



import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false;

Vue.use(BootstrapVue)
Vue.component('BIcon', BIcon)
Vue.component('BIconQuestionCircle', BIconQuestionCircle)

new Vue({
    render: h => h(App),
}).$mount('#app');
